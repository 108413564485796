<template>
    <div id="wdc-app" :class="{ 'wdc-app': true,}">
        <template v-if="isHeader === false">
            <Menu />
            <div ref="main" class="main" style="background-color: #fff">
                <keep-alive>
                    <router-view v-if="$route.meta.keepAlive" />
                </keep-alive>
                <router-view v-if="!$route.meta.keepAlive" :key="key" />
            </div>
        </template>
        <template v-if="isHeader === true">
            <router-view />
        </template>
        <Footer v-show="isShowFooter && !excludeNavPage.includes($route.path) && $route.path!='/cms/detail'"/>
        <!-- gpt悬浮窗 网站配置了ai助手 && 页面配置ai助手入口 -->
        <div  v-if="$route.meta?.gpt?.gptType && !($route.name=='homeyj' || $route.name=='homegw')" :class="'gpt-'+$route.name">
            <GptFloating  @openAi="changeVisible"
                :text="$route?.meta?.gpt?.text"
                :src="$route?.meta?.gpt?.imgUrl"
                v-wd-drag/>
            <el-drawer
                :modal="false"
                :wrapperClosable="false"
                class="assistant-box"
                :size="560"
                :visible.sync="aiVisibleChat"
                :with-header="false">
                <ai-assistant @openAi="changeVisible" :aiVisibleChat="aiVisibleChat" :gptType="$route.meta?.gpt?.gptType" :gptText="$route.meta?.gpt?.text" :contentId="$store.state.coures.currentlyPlay.contentId" v-if="aiVisibleChat"></ai-assistant>
            </el-drawer>
        </div>
    </div>
</template>

<script>
import Menu from './view/head/crowd/module/menu.vue'
import Footer from './view/footer/footer.vue'
import isOfficial from '@/utils/common'
import GptFloating from '@/modules/gpt/components/GptFloating.vue';
import AiAssistant from '@/modules/gpt/page/index.vue'
import wdDrag from '@/directive/drag' //拖拽
export default {
    components: {
        Menu,
        Footer,
        AiAssistant,
        GptFloating
    },
    data() {
        return {
            aiVisibleChat:false,
            isShowFooter: false,
            excludeNavPage: ['/login', '/register', '/feRegister','/course/play', '/exam/testPaper'],
            pageEndSetting: null,
            token: localStorage.getItem('token'),
            isHeader: null,
        }
    },
    directives:{
        wdDrag
    },
    watch: {
        $route() {
            this.aiVisibleChat = false;
            // ai助手位置初始化
           if(document.querySelector('.gptFloating')){
                document.querySelector('.gptFloating').style.left = 'auto';
                document.querySelector('.gptFloating').style.right = 0;
                document.querySelector('.gptFloating').style.bottom = 252 + 'px';
                document.querySelector('.gptFloating').style.top = 'auto';
            }
            if (this.excludeNavPage.includes(this.$route.path)) {
                // 不展示导航头
                this.isHeader = true
            } else {
                this.isHeader = false // 展示导航头
            }
        },
    },
    computed: {
        // isLogin() {
        //   return !!localStorage.getItem('token')
        // },
        key() {
            return this.$route.name !== undefined ? this.$route.name + new Date() : this.$route + new Date()
        },
    },
    async created() {
        this.isShowFooter = isOfficial;
    },
    async mounted() {
        let setTempConfigs = localStorage.getItem('setTempConfigs') && JSON.parse(localStorage.getItem('setTempConfigs'))
        this.pageEndSetting = setTempConfigs.pageEnd && JSON.parse(setTempConfigs.pageEnd)

        document.addEventListener('visibilitychange', () => {
            var isHidden = document.hidden
            if (!isHidden) {
                let localStorageId = localStorage.getItem('subUserInfo') && JSON.parse(localStorage.getItem('subUserInfo')).accountId
                let vuexId = this.$store.state.subUserInfo.accountId
                if (localStorageId && vuexId && localStorageId !== vuexId) {
                    //重新加载页面，保持账号同步
                    // this.$router.replace('/')
                    this.$router.go(0)
                }
            }
        })
        // 开放平台-请求学员数据
        localStorage.setItem('loginBy', 0)
    },
    methods: {
        changeVisible(data){
            if(!localStorage.getItem('token')){
                this.$router.push({
                    path:'/login'
                })
                return
            }
            if(document.querySelector('.gptFloating') && document.querySelector('.gptFloating').getAttribute('data-drag')==='true'){
                return
            }
            if(data===false){
                this.aiVisibleChat = false;
            }else{
                this.aiVisibleChat = !this.aiVisibleChat;
            }
        },
    },
}
</script>
<style lang="scss" src="./modules/gpt/assets/css/index.scss" scoped>

</style>
<style lang="scss">
.main{
  margin-top:72px;
}
.backtop {
  overflow-y: scroll;
}

.flex {
  display: flex;
}

.lr-flex {
  justify-content: space-between;
}

.el-message {
  min-width: 150px;
}

.el-message.is-closable .el-message__content {
  padding-right: 36px;
}

/* 选项卡tabs样式修改 */
.common-page {
  .el-tabs {
    .el-tabs__nav-wrap::after {
      display: none;
    }

    .el-tabs__nav-scroll {
      .el-tabs__nav {
        .el-tabs__active-bar {
          display: none;
        }

        .el-tabs__item {
          padding: 0;
          margin: 24px 12px;
          font: 500 18px PingFangSC-Medium, PingFang SC;
          color: #333;

          &:hover {
            color: #1972FF !important;
          }
        }

        .is-active {
          color: #1972FF !important;
        }
      }
    }
  }
}

/* *一分栏  二分栏 选项卡  样式 */
div[data-comp-name='12-12'], div[data-comp-name='1-2'], div[data-comp-name='Tabs'] {
  width: 1200px;
  margin: 0 auto;
}
</style>
<style scoped lang="scss">
.main{
  ::v-deep  .certificate{
      background-color: #f9f9fa!important;
    }

}
</style>
<!-- <style lang="scss" scoped src="../src/modules/gpt/assets/css/index.scss">
</style> -->
