
export default [
    {
        path: '/kbase',
        name: 'kbase',
        meta: {
            weight: 0,
            login: false,
        },
        component: () => import(/* webpackChunkName: 'kbase' */ './index.vue'),
    },
    {
        path: '/law/list',
        name: 'lawList',
        meta: {
            weight: 0,
            login: false,
            parent:'/kbase'
        },
        component: () => import(/* webpackChunkName: 'kbase' */ './law/index.vue'),
    },
    {
        path: '/law/detail',
        name: 'lawDetail',
        meta: {
            weight: 0,
            login: false,
            parent:'/kbase'
        },
        component: () => import(/* webpackChunkName: 'kbase' */ './law/detail.vue'),
    },
    {
        path: '/hazardData/list',
        name: 'hazardDataList',
        meta: {
            weight: 0,
            login: false,
            parent:'/kbase'
        },
        component: () => import(/* webpackChunkName: 'hazardData' */ './hazard/index.vue'),
    },
    {
        path: '/hazardData/detail',
        name: 'hazardDataDetail',
        meta: {
            weight: 0,
            login: false,
            parent:'/kbase'
        },
        component: () => import(/* webpackChunkName: 'hazardData' */ './hazard/detail.vue'),
    },{
        path: '/accident/list',
        name: 'accidentList',
        meta: {
            weight: 0,
            login: false,
            parent:'/kbase'
        },
        component: () => import(/* webpackChunkName: 'accident' */ './accident/index.vue'),
    },
    {
        path: '/accident/detail',
        name: 'accidentDetail',
        meta: {
            weight: 0,
            login: false,
            parent:'/kbase'
        },
        component: () => import(/* webpackChunkName: 'accident' */ './accident/detail.vue'),
    }
]